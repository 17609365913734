<template>
    <div class="common-layout">
        <el-container>
            <el-header v-if="this.langugue == 'C'" class="head">
            </el-header>
            <el-header v-else class="headEng">
            </el-header>
            <!-- 标题 -->
            <div style="display: flex;height: 40px;width: 1450px;background-color: black;margin: auto;">
                <div style="display: flex;justify-content: space-around;text-align: center;width: 900px;">
                    <a v-if="this.langugue == 'C'" href="" style=" margin-left: 200px;color:orange" @click="goPage('/')">
                        首页
                    </a>
                    <a v-else href="" style=" margin-left: 200px;color:orange" @click="goPage('/')">
                        Home
                    </a>
                    <a v-if="this.langugue == 'C'" href="" @click="goPage('ProductsPage')">
                        产品
                    </a>
                    <a v-else href="" @click="goPage('ProductsPage')">
                        Products
                    </a>
                    <a v-if="this.langugue == 'C'" href="" @click="goPage('CraftPage')">
                        工艺
                    </a>
                    <a v-else href="" @click="goPage('CraftPage')">
                        Craft
                    </a>
                    <a v-if="this.langugue == 'C'" href="" @click="goPage('AboutPage')">
                        关于我们
                    </a>
                    <a v-else href="" @click="goPage('AboutPage')">
                        About
                    </a>
                    <a v-if="this.langugue == 'C'" href="" @click="goPage('ContactPage')">
                        联系我们
                    </a>
                    <a v-else href="" @click="goPage('ContactPage')">
                        Contact
                    </a>
                </div>


                <div style="display: flex;width: 500px;justify-content: center;align-content: center;color: white;">
                    <a href="" @click="changeLan('E')" v-bind:style="{ color: langugue === 'E' ? 'orange' : 'white' }">
                        En
                    </a>
                    <span style="text-align: center;line-height:40px;"> &nbsp;|&nbsp; </span>
                    <a href="" style="" @click=" changeLan('C')"
                        v-bind:style="{ color: langugue === 'C' ? 'orange' : 'white' }">
                        中
                    </a>
                </div>

            </div>
            <el-main class="main">

                <!-- 幻灯片 -->
                <div>
                    <el-carousel height="455px" indicator-position="outside">
                        <el-carousel-item v-for="item in images" :key="item.key">
                            <img :src="item.path" style="width: 100%;" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div>
                    <div style="display: flex;justify-content: center;">
                        <span v-if="this.langugue == 'C'"
                            style="color: rgb(255, 166, 0);font-size: 40px;margin: 30px auto;">分类导航</span>
                        <span v-else style="color: orange;font-size: 40px;margin: 30px auto;">CATEGORY NAVIGATION</span>
                    </div>

                    <div style=" margin: 0 auto;width: 100px;">
                        <img :src="fontImgs[1]" style="margin-top: 10px;">
                    </div>
                    <div class="modelList" style="height: 310px;width: 1200px; margin: 60px auto;">
                        <div class="model1">
                            <span>{{ this.langugue == 'C' ? '太阳眼镜' : 'Sun Grasses' }}</span>
                        </div>
                        <div class="model2">
                            <span>{{ this.langugue == 'C' ? '运动眼镜' : 'Sports Grasses' }}</span>
                        </div>
                        <div class="model3">
                            <span>{{ this.langugue == 'C' ? '滑雪眼镜' : 'Ski Grasses' }}</span>
                        </div>
                        <div class="model4">
                            <span>{{ this.langugue == 'C' ? '光学眼镜' : 'Optical Grasses' }}</span>
                        </div>
                    </div>
                    <br /> <br /> <br />
                    <div style="display: flex;justify-content: center;">
                        <span v-if="this.langugue == 'C'"
                            style="color: orange;font-size: 40px;margin: 30px auto;">全景观看</span>
                        <span v-else style="color: orange;font-size: 40px;margin: 30px auto;">PANORAMA VIEWING</span>
                    </div>

                    <div style="margin: 0 auto;width: 100px;">
                        <img :src="fontImgs[1]" style="margin-top: 10px;">
                    </div>
                    <br /><br /><br />
                    <div class="vrRoom" @click="handleClick">
                        <span style="margin: auto auto;">{{ this.langugue == 'C' ? 'VR展厅' : 'VR Showroom' }}</span>
                    </div>
                    <!-- <img :src="fontImgs[3]" style="margin-top: 10px;"> -->
                </div>
                <br /><br /><br />

                <div v-if="this.langugue == 'C'" class="backgroundStyle">
                </div>
                <div v-else class="backgroundStyleEng">
                </div>
                <div v-if="this.langugue == 'C'" class="backgroundStyle2">
                    <video id="myVideo" src="https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/video/官网视频.mp4"
                        controls></video>
                </div>
                <div v-else class="backgroundStyle2Eng">
                    <video id="myVideo" src="https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/video/官网视频.mp4"
                        controls></video>
                </div>
                <div class="bg">
                    <br />
                    <div class="myContainer">
                        <div class="image-container">
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/1.jpg'
                                alt="p1" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/2.jpg'
                                alt="p2" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/3.jpg'
                                alt="p3" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/4.jpg'
                                alt="p4" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/5.jpg'
                                alt="p5" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/6.jpg'
                                alt="p6" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/7.jpg'
                                alt="p7" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/8.jpg'
                                alt="p8" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/9.jpg'
                                alt="p9" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/10.jpg'
                                alt="p10" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/11.jpg'
                                alt="p11" />
                            <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/12.jpg'
                                alt="p12" />
                        </div>
                    </div>
                    <br />
                    <div class="image-container2">
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c1.jpg' alt="p1" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c2.jpg' alt="p2" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c3.jpg' alt="p3" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c4.jpg' alt="p4" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c5.jpg' alt="p5" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c6.jpg' alt="p6" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c7.jpg' alt="p7" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/c8.jpg' alt="p8" />
                        <!-- <img src='@/assets/headPage/c1.jpg' alt="p1" />
                        <img src='@/assets/headPage/c2.jpg' alt="p2" />
                        <img src='@/assets/headPage/c3.jpg' alt="p3" />
                        <img src='@/assets/headPage/c4.jpg' alt="p4" /> -->
                        ...
                        <!-- <img src="p10.jpg" alt="p10" /> -->
                    </div>
                    <br />
                </div>
                <div v-if="this.langugue == 'C'" class="backgroundStyle3">

                </div>
                <div v-else class="backgroundStyle3Eng">

                </div>



            </el-main>

            <el-footer>
                <div class="top" @click="goTop">
                </div>
                <br /><br /><br /><br />
                <div v-if="this.langugue == 'C'" class="backgroundStyle4">
                </div>
                <div v-else class="backgroundStyle4Eng">
                </div>
            </el-footer>


        </el-container>
    </div>
</template>
  
  
<script>
import router from '@/router/index.js'
export default {
    name: 'HeadPage',
    props: {
        msg: String,
    },
    data() {
        return {
            images: [
                { key: 1, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/1.jpg') },
                { key: 2, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/2.jpg') },
                { key: 3, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/3.jpg') },
                { key: 4, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/4.jpg') }
            ],
            fontImgs: [
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/CATEGORY.png'),
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/矩形.png'),
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/Panorama.png'),
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/vrRoom.png'),

            ],
            modelImags: [
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/headPage/font/model1.png',
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/headPage/font/model2.png',
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/headPage/font/model3.png',
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/headPage/font/model4.png',
            ],
            langugue: 'C'
        }
    },
    mounted() {
        if (this.$route.query.langugue === undefined) return
        console.log('@@', this.$route.query.langugue);
        this.langugue = this.$route.query.langugue
    },
    methods: {
        goPage(value) {
            event.preventDefault()
            this.$router.push({
                path: value,
                query: {
                    langugue: this.langugue
                }
            });
        },


        handleClick() {
            window.location.href = 'https://v.holoworld.com.cn/v/virtualbuy.html?id=f14779e1-603d-4d4e-bc52-4c0d369c102e';
        },
        changeLan(value) {
            event.preventDefault()
            this.langugue = value
        },
        goTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
    margin: 0;
    padding: 0;
}

.main {
    background-color: #F3F5F7;
    width: 1450px;
    margin: 0 auto;
    overflow-x: hidden;
    /*页面上的水平滚动条就会被隐藏。*/

}

.head {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/%E6%8B%9B%E7%89%8C.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    margin-bottom: 0;
    width: 100%;
}

.headEng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/eng/招牌.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 200px;
    margin-bottom: 0;
    width: 100%;
}

.modelList {
    display: flex;
    justify-content: center;
    height: 310px;
    width: 1200px;
    margin: 60px auto;
}

.model1 {
    display: flex;
    justify-content: center;
    height: 350px;
    width: 280px;
    /* float: left; */
    /* background-color: aqua; */
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model1.png');
    transition: all 0.5s;
    line-height: 350px;
    color: white;
}



.model2 {
    display: flex;
    justify-content: center;
    height: 350px;
    width: 280px;
    /* float: left; */
    /* background-color: yellow; */
    margin-left: 26px;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model2.png');
    line-height: 350px;
    color: white;

}

.model3 {
    display: flex;
    justify-content: center;
    height: 350px;
    width: 280px;
    /* float: left; */
    /* background-color: aqua; */
    margin-left: 26px;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model3.png');
    line-height: 350px;
    color: white;
}

.model4 {
    display: flex;
    justify-content: center;
    height: 350px;
    width: 280px;
    /* float: left; */
    /* background-color: yellow; */
    margin-left: 26px;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model4.png');
    line-height: 350px;
    color: white;
}

.vrRoom {
    display: flex;
    height: 600px;
    width: 100%;
    /* float: left; */
    /* background-color: yellow; */
    /* margin-left: 26px; */
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/vrRoom.png');
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;
    align-items: center;
}

.vrRoom span {
    color: white;
    font-size: 25px;
}

.model1:hover {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/lightenModel1.png');
    transform: scale(1.3);
}

.model2:hover {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/lightenModel2.png');
    transform: scale(1.3);
}

.model3:hover {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/lightenModel3.png');
    transform: scale(1.3);
}

.model4:hover {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/lightenModel4.png');
    transform: scale(1.3);

}

.vrRoom:hover {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/lightenVrRoom.png');
    transform: scale(1.3);
}

a {
    text-decoration: none;
    float: left;
    color: white;
    line-height: 40px;
}

a:hover {
    color: orange !important;
}

.backgroundStyle {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/上.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 885.99px;
}

.backgroundStyleEng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/eng/上.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 885.99px;
}

.backgroundStyle2 {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/中.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 425px;
    width: 100%;
}

.backgroundStyle2Eng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/eng/中.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 425px;
    width: 100%;
}

#myVideo {
    float: right;
    width: 40%;
    height: 400px;
    margin-right: 180px;
}


.backgroundStyle3 {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/地图.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 826px;
    width: 1450px;
    margin-top: 80px;
}

.backgroundStyle3Eng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/eng/地图.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 826px;
    width: 1450px;
    margin-top: 80px;
}

.bg {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/bg.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 826px;
    width: 100%;
}

.top {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/top.png');
    float: right;
    margin-right: 350px;
    width: 73px;
    height: 73px;
    z-index: 1000PX;

}

.backgroundStyle4 {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/联系方式.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    width: 100%;
    margin-top: 0px;
}

.backgroundStyle4Eng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/eng/联系方式.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 500px;
    width: 100%;
    margin-top: 0px;
}


.myContainer {
    height: 500px;
    width: 1450px;
    overflow: hidden;
    margin: 0 auto;
    overflow-x: hidden
}


.image-container {
    display: flex;
    justify-content: space-between;
    /* animation: slide 20s linear infinite; */
    animation: slide 20s linear infinite alternate;
}

.image-container2 {
    display: flex;
    justify-content: space-between;
    animation: slide2 20s linear infinite alternate;
}

.image-container img {
    width: 25%;
    height: 100%;
    margin-left: 50px;
}

.image-container2 img {
    width: 25%;
    height: 100%;
    margin-left: 20px;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-3600px);
    }
}

@keyframes slide2 {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-1700px);
    }
}
</style>
  