<template>
    <div>
        <div @click="toggleMenu" class="picMenu">
            <img src="https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/menu.png">
        </div>
        <!-- <button @click="toggleMenu">Menu</button> -->
        <ul v-if="showMenu">
            <li v-if="this.langugue == 'C'">
                <router-link v-bind:style="{ color: page === 'HeadPage' ? 'orange' : 'black' }"
                    :to="{ name: 'HeadPage', query: { langugue } }">首页</router-link>
            </li>
            <li v-else>
                <router-link v-bind:style="{ color: page === 'HeadPage' ? 'orange' : 'black' }"
                    :to="{ name: 'HeadPage', query: { langugue } }">Home</router-link>
            </li>


            <li v-if="this.langugue == 'C'">
                <router-link v-bind:style="{ color: page === 'ProductsPage' ? 'orange' : 'black' }"
                    :to="{ name: 'ProductsPage', query: { langugue } }">产品</router-link>
            </li>
            <li v-else>
                <router-link v-bind:style="{ color: page === 'ProductsPage' ? 'orange' : 'black' }"
                    :to="{ name: 'ProductsPage', query: { langugue } }">Products</router-link>
            </li>


            <li v-if="this.langugue == 'C'">
                <router-link v-bind:style="{ color: page === 'CraftPage' ? 'orange' : 'black' }"
                    :to="{ name: 'CraftPage', query: { langugue } }">工艺</router-link>
            </li>
            <li v-else>
                <router-link v-bind:style="{ color: page === 'CraftPage' ? 'orange' : 'black' }"
                    :to="{ name: 'CraftPage', query: { langugue } }">Craft</router-link>
            </li>


            <li v-if="this.langugue == 'C'">
                <router-link v-bind:style="{ color: page === 'AboutPage' ? 'orange' : 'black' }"
                    :to="{ name: 'AboutPage', query: { langugue } }">关于我们</router-link>
            </li>
            <li v-else>
                <router-link v-bind:style="{ color: page === 'AboutPage' ? 'orange' : 'black' }"
                    :to="{ name: 'AboutPage', query: { langugue } }">About Us</router-link>
            </li>


            <li v-if="this.langugue == 'C'">
                <router-link v-bind:style="{ color: page === 'ContactPage' ? 'orange' : 'black' }"
                    :to="{ name: 'ContactPage', query: { langugue } }">联系我们</router-link>
            </li>
            <li v-else>
                <router-link v-bind:style="{ color: page === 'ContactPage' ? 'orange' : 'black' }"
                    :to="{ name: 'ContactPage', query: { langugue } }">Contact Us</router-link>
            </li>

        </ul>
    </div>
</template>
  
<script>
export default {
    name: 'MenuPage',
    props: {
        langugue: String,
        page: String,
    },
    data() {
        return {
            showMenu: false
        }
    },
    mounted() {
        console.log('page', this.page);
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        }
    }
}
</script>
  
<style scoped>
/* 在这里添加样式 */
ul {
    display: flex;
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 10px;
    width: 39.023vw;
    height: 75.556vw;
    border: solid 1px black;
    border-radius: 10px;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    margin-left: -30vw;
    position: absolute;
    z-index: 10000;
}

ul:before {
    content: "";
    position: absolute;
    top: -10px;
    left: calc(75% - 8px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px;
    border-color: transparent transparent black;
}

li {
    margin: 10px 0;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: peachpuff;
}



.picMenu {
    width: 5.833vw;
    /* height: 4.306vw;  不注释，无法居中*/
    overflow: hidden;
    margin-right: 10.028vw;
    /* margin-left: 30.028vw; */
    /* background-color: yellow; */
}

.picMenu img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* padding-right: 0vw; */

}
</style>
  