<template>
    <div class="common-layout">

        <div class="head">
            <div class="pic">
                <img src="https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/Logo.png">
            </div>

            <div class="langugue">
                <a href="" @click="changeLan('E')" v-bind:style="{ color: langugue === 'E' ? 'orange' : 'black' }">
                    En
                </a>
                <span> &nbsp;|&nbsp; </span>
                <a href="" @click=" changeLan('C')" v-bind:style="{ color: langugue === 'C' ? 'orange' : 'black' }">
                    中
                </a>
            </div>


            <div style="line-height: 11.111vw;">
                <Mune :langugue="langugue" :page="page"></Mune>
            </div>
        </div>

        <!-- 轮播图 -->
        <div>
            <el-carousel height="52.777vw" indicator-position="outside">
                <el-carousel-item v-for="item in images" :key="item.key">
                    <img :src="item.path" style="width: 100%;" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 模特像 -->
        <div class="main">
            <div class="font">
                <div style="width: 59.444vw; height:10vw; margin: 0 auto;">
                    <p v-if="this.langugue == 'C'" style="">分类导航</p>
                    <p v-else style="font-size:5vw;">CATEGORY NAVIGATION</p>
                </div>
                <div style="width: 15vw; height:5vw;margin: 0 auto;">
                    <img :src="fontImgs[1]" style="margin-top: 10px;">
                </div>
            </div>

            <br />
            <div class="modelList">
                <div class="model1">
                    <span v-if="this.langugue == 'C'" style="margin: 50% auto;">太阳眼镜</span>
                    <span v-else style="margin: 50% auto;">Sun Grasses</span>
                </div>
                <div class="model2">
                    <span v-if="this.langugue == 'C'" style="margin: 50% auto;">运动眼镜</span>
                    <span v-else style="margin: 50% auto;">Sports Grasses</span>
                </div>
                <div class="model3">
                    <span v-if="this.langugue == 'C'" style="margin: 50% auto;">滑雪眼镜</span>
                    <span v-else style="margin: 50% auto;">Ski Grasses</span>
                </div>
                <div class="model4">
                    <span v-if="this.langugue == 'C'" style="margin: 50% auto;">光学眼镜</span>
                    <span v-else style="margin: 50% auto;">Optical Grasses</span>
                </div>
            </div>

            <br />
            <div class="font">
                <div style="width: 59.444vw; height:10vw; margin: 0 auto;">
                    <p v-if="this.langugue == 'C'" style="">全景观看</p>
                    <p v-else style="font-size:5vw;">PANORAMA VIEWING</p>
                </div>
                <div style="width: 15vw; height:5vw;margin: 0 auto;">
                    <img :src="fontImgs[1]" style="margin-top: 10px;">
                </div>
            </div>
            <br />
            <div class="vrRoom" @click="handleClick">
                <span v-if="this.langugue == 'C'" style="margin: auto auto;">VR展厅</span>
                <span v-else style="margin: auto auto;">VR ShowRoom</span>
            </div>

            <br /><br />
            <div v-if="this.langugue == 'C'" class="background1">
            </div>
            <div v-else class="background1Eng">
            </div>
            <div class="background2">
                <video id="myVideo" src="https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/video/官网视频.mp4"
                    controls></video>
            </div>
            <div class="background3">
                <br />
                <div class="myContainer">
                    <div class="image-container">
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/1.jpg'
                            alt="p1" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/2.jpg'
                            alt="p2" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/3.jpg'
                            alt="p3" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/4.jpg'
                            alt="p4" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/5.jpg'
                            alt="p5" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/6.jpg'
                            alt="p6" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/7.jpg'
                            alt="p7" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/8.jpg'
                            alt="p8" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/9.jpg'
                            alt="p9" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/10.jpg'
                            alt="p10" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/11.jpg'
                            alt="p11" />
                        <img src='https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/certificate/12.jpg'
                            alt="p12" />
                    </div>
                </div>
            </div>
            <br /><br />
            <div v-if="this.langugue == 'C'" class="map">
            </div>
            <div v-else class="mapEng">
            </div>
        </div>
        <br />
        <div v-if="this.langugue == 'C'" class="foot">
        </div>
        <div v-else class="footEng">
        </div>
    </div>
</template>


<script>
import Mune from './mobile/MunePage.vue'
export default {
    name: 'HeadPageM',
    components: {
        Mune,
    },
    data() {
        return {
            images: [
                { key: 1, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/1.jpg') },
                { key: 2, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/2.jpg') },
                { key: 3, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/3.jpg') },
                { key: 4, path: ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/4.jpg') }
            ],
            fontImgs: [
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/CATEGORY.png'),
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/矩形.png'),
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/Panorama.png'),
                ('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/vrRoom.png'),

            ],
            modelImags: [
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model1.png',
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model2.png',
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model3.png',
                'https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model4.png',
            ],
            langugue: 'C', //E为英文
            page: 'HeadPage'

        }
    },
    mounted() {
        if (this.$route.query.langugue === undefined) return
        console.log('@@', this.$route.query.langugue);
        this.langugue = this.$route.query.langugue
    },
    methods: {
        changeLan(value) {
            event.preventDefault()
            this.langugue = value
        }
    }
}
</script>
<style scoped>
* {
    margin: 0;
    margin-top: 0%;
}


.head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 4.028vw 3%;
    align-content: center;
}

.head .pic {
    width: 36.8056vw;
    height: 11.111vw;
    overflow: hidden;
    /* margin-left: 3.889vw; */
}

.head .pic img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.head .langugue {
    display: flex;
    text-align: center;
    line-height: 11.111vw;
    align-content: center;
}

.head .picMenu {
    width: 5.833vw;
    /* height: 4.306vw;  不注释，无法居中*/
    overflow: hidden;
    margin-right: 10.028vw;
    /* margin-left: 30.028vw; */
    background-color: yellow;
}

.head .picMenu img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* padding-right: 0vw; */

}

a {
    text-decoration: none;
    float: left;
    /* color: white; */
    line-height: 40px;
}

a:hover {
    color: orange !important;
}


.main {
    margin-top: 5vw;
}

.main .font {
    font-size: 6vw;
    text-align: center;
    color: orange;
}

.main .font div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main .font img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.main .modelList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.main .modelList .model1 {
    height: 57.361vw;
    width: 45.833vw;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model1.png');
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.5s;
    line-height: 350px;
    color: white;
    margin-bottom: 5vw;

}

.main .modelList .model2 {
    height: 57.361vw;
    width: 45.833vw;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model2.png');
    background-size: contain;
    transition: all 0.5s;
    line-height: 350px;
    color: white;
    margin-bottom: 5vw;
}

.main .modelList .model3 {
    height: 57.361vw;
    width: 45.833vw;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model3.png');
    background-size: contain;
    transition: all 0.5s;
    line-height: 350px;
    color: white;
    margin-bottom: 5vw;
}

.main .modelList .model4 {
    height: 57.361vw;
    width: 45.833vw;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/font/model4.png');
    background-size: contain;
    transition: all 0.5s;
    line-height: 350px;
    color: white;
    margin-bottom: 5vw;
}

.modelList>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vrRoom {
    display: flex;
    height: 51.389vw;
    width: 100%;
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/headPage/vrRoom.png');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 4vw;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 5vw;
}

.background1 {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/上.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 198vw;
}

.background1Eng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/eng/headPage/上.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 198vw;
}

.background2 {
    /* background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/中.png'); */
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 58vw;
    /* margin-top: -1%; */
}

#myVideo {
    width: 100%;
    /* height: 400px; */
    /* margin-right: 180px; */
}


.background3 {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 63.889vw;
    /* margin-top: -2.5%; */
}

.myContainer {
    height: 50vw;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
    overflow-x: hidden
}

.image-container {
    display: flex;
    justify-content: space-between;
    /* animation: slide 20s linear infinite; */
    animation: slide 20s linear infinite alternate;
}

.image-container img {
    width: 35%;
    height: 100%;
    margin-left: 5vw;
}

@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-248.276vw);
    }
}


.map {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/地图.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 92.778vw;
}

.mapEng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/eng/headPage/地图.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 92.778vw;
}

.foot {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/headPage/联系方式.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 46.666vw;
}

.footEng {
    background-image: url('https://vue-he2.oss-cn-shenzhen.aliyuncs.com/assets/mobile/eng/headPage/联系方式.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 46.666vw;
}
</style>