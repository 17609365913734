import { createRouter, createWebHashHistory } from 'vue-router'
import HeadPage from '../components/HeadPage.vue'
import HeadPageM from '../components/HeadPageM.vue'
// import AboutPage from '../components/AboutPage.vue'
// import ContactPage from '../components/ContactPage.vue'
// import CraftPage from '../components/CraftPage.vue'
// import ProductsPage from '../components/ProductsPage.vue'


const AboutPage = () => import('../components/AboutPage.vue')
const ContactPage = () => import('../components/ContactPage.vue')
const CraftPage = () => import('../components/CraftPage.vue')
const ProductsPage = () => import('../components/ProductsPage.vue')

const AboutPageM = () => import('../components/AboutPageM.vue')
const ContactPageM = () => import('../components/ContactPageM.vue')
const CraftPageM = () => import('../components/CraftPageM.vue')
const ProductsPageM = () => import('../components/ProductsPageM.vue')
// const history = createWebHashHistory ()

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/',
            name: 'HeadPage',
            // component: HeadPage
            component: (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? HeadPageM : HeadPage
        },
        {
            path: '/AboutPage',
            name: 'AboutPage',
            // component: AboutPage
            component: (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? AboutPageM : AboutPage
        },
        {
            path: '/ContactPage',
            name: 'ContactPage',
            // component: ContactPage
            component: (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? ContactPageM : ContactPage
        },
        {
            path: '/CraftPage',
            name: 'CraftPage',
            // component: CraftPage
            component: (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? CraftPageM : CraftPage
        },
        {
            path: '/ProductsPage',
            name: 'ProductsPage',
            // component: ProductsPage
            component: (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) ? ProductsPageM : ProductsPage
        }
    ]
});


export default router